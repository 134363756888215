* {
  box-sizing: border-box;
}

/* @font-face {
  font-family: 'Raleway', sans-serif;
} */

#root {
  position: relative;
  margin: 0;
  padding: 0;
}

:root {
  --principal: #7bcad8;
  --secundario: #1f8280;
  --secundario_2: #d7165b;
  --secundario_3: #fbbb44;
}

body {
  font-family: 'Raleway', sans-serif;
  color: #1f8280;
  background-color: #E8EDF5;
  background-image: linear-gradient(0deg, rgb(24, 29, 40, 0.1), rgb(24, 29, 40, 0.1)), url('./img/1.jpeg');
  background-size: cover;
  background-attachment: fixed;
}

.logo_text {
  /* height: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--secundario);
  font-size: 15px;
  font-weight: bold;
  border-left: 1px solid var(--secundario);
  padding: 15px;
  margin: 0px;
}

/* h1, h2 {
    font-weight: bold;
  } */


.donacion-btn {
  color: #d7165b;
  background: #fbba44;
  border-radius: 10px;
  padding: 10px;
}

.donacion-btn:hover {
  background: #fbbb44e3;
}

.fixed-unfollow-links {
  /* border-bottom-right-radius: 50px;
  border-top-right-radius: 50px; */
  /* background-image: url('./img/pagos-pse.png'); */
  background-color: rgb(31, 73, 128);
  height: auto;
  position: fixed;
  top: 80%;
  right: -110px;
  width: 200px;
  filter: drop-shadow(1px 5px 7px #082564);
  z-index: 99999999;
  transition: all 0.2s ease-in-out;
  /* padding: 35px; */
  border-radius: 40px 0 0 40px;
  display: flex;
  flex-direction: row;
}

.facebook-icon {
  background-image: url('./img/facebook.png');
  width: 30px;
  height: 30px;
}

.instagram-icon {
  background-image: url('./img/instagram.png');
  width: 30px;
  height: 30px;
}

.wraper {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #E8EDF5;
  top: 0px
}

.navbar {
  background-color: var(--principal);
}

.hidde {
  display: none;
}

.nav_dropdown {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav_dropdown:hover .hidde {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #E8EDF5;
  top: 50px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  min-height: 100px;
}

.flexDirection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex {
  display: flex;
  justify-content: center;
  flex: 0 0 33%;
}

.flex div p a {
  color: var(--secundario);
  font-weight: bold;
}

.redes-header {
  display: flex;
  margin: 0 20px;
}

.navbar-nav {
  text-decoration: none;
  color: var(--secundario);
  height: 50px;
  justify-content: center;
  font-weight: bold;
  margin: 0 30px;
}

.container {
  max-width: 1696px;
  background-color: #E8EDF5;
}

.navbar .container-fluid {
  display: flex;
  max-width: 1696px;
  justify-content: center;
  position: relative;
}

.navbar-button {
  display: none;
  margin: 10px;
}

.slider {
  margin: auto;
  width: 100%;
  padding-top: 217px;
  max-width: 1696px;
  /* background-color: white; */
  /* border-radius: 20px; */
}

.slider .slider_container {
  width: 100%;
}

.slider .slider_container .inner_slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  /* Alineación a la derecha */
  align-items: center;
  overflow: hidden;
  max-height: 100%;
  min-height: 300px;
  /* Altura mínima ajustada según tus necesidades */

  @media (min-width: 600px) {
    min-height: 500px;
    /* Altura mínima ajustada para pantallas más grandes */
  }

  @media (min-width: 1200px) {
    min-height: 800px;
    /* Altura mínima ajustada para pantallas aún más grandes */
  }
}

.slider .slider_container .inner_slider .slide_item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  /* max-width: 1380px; */
  animation: flasher 2s;
}

.inicio {
  width: 100%;
}

.inicio .slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1550px;
  max-height: 850px;
  padding-top: 0;
  margin-top: 217px;
}

.inicio .slider .slider_inner {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}

.inicio .slider .slider_inner .items_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: transform 1s ease;
}

.inicio .slider .slider_inner .items_container .item {
  flex: 0 0 100%;
  /* Evita que los elementos se redimensionen */
  max-width: 1550px;
  max-height: 850px;
}

.inicio .slider .slider_inner .items_container .item img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
  /* Escalado para cubrir completamente el contenedor */
}

.portafolio .slider {
  padding-top: 0px;
}

.inicio .slider-arrow-left {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 20px;
}

.inicio .slider-arrow-right {
  position: absolute;
  top: 40%;
  z-index: 10;
  right: 20px;
}

@keyframes flasher {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes entrando_texto {
  from {
    transform: translate(-30vw);
  }

  to {
    transform: translate(0px);
  }
}

@keyframes entrando_imagen {
  from {
    transform: translate(-10vw);
  }

  to {
    transform: translate(0px);
  }
}

@keyframes saliendo_imagen {
  from {
    transform: translate(0vw);
  }

  to {
    transform: translate(-10px);
  }
}

/* .slider .slider_container .inner_slider .slide_item h2{
  width: 50%;
  animation: entrando_texto 1s;
} */
.slider .slider_container .inner_slider .slide_item img {
  /* max-height: 840px; */
  width: 100%;
  border-radius: 10px;
  /* animation: entrando_imagen 2s; */
  transition: opacity 0.5s ease-in-out;
}

.slider-arrow {
  cursor: pointer;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: 20px;
  text-align: center;
  line-height: 36px;
}

.slider-arrow:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.slider-arrow-left {
  /* position: absolute; */
  /* top: 40%; */
  z-index: 10;
  /* left: 20px; */
}

.slider-arrow-right {
  /* position: absolute; */
  /* top: 40%; */
  z-index: 10;
  /* right: 20px; */
}

.section {
  width: 100%;
  background-color: white;
  padding: 50px 50px;
  /* color: black; */
  margin: 47px auto;
  max-width: 1696px;
  border-radius: 10px;
}

.section .section_inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.section .section_inner .section_text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding-right: 10px;
}

.section .section_inner .section_text h2 {
  width: 100%;
  font-size: 50px;
  font-weight: bold;
}

.section .section_inner .section_text p {
  width: 100%;
  font-size: 25px;
  text-align: justify;
}

.section .section_inner .section_img img {
  width: 400px;
  border-radius: 10px;
}

.banner_secundario {
  width: 100%;
  height: 600px;
  position: relative;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.banner_secundario .slogan {
  height: 100%;
  max-width: 1696px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  right: 0;
  /* justify-content: flex-end; */
  color: white;

}

.banner_secundario .slogan h1 {
  font-size: 35px;
  font-weight: bold;
  width: 70%;
}

.section .card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

/* .section .card-container .card{
  width: 100%;
  margin: 0 15px;
  background-size: cover;
  background-position: center;
  height: 500px;
} */

.card {
  width: 100%;
  margin: 20px 15px;
  height: 70vh;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  min-width: 200px;
  max-width: 400px;
}

.card .image-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  /* background-color: rgb(77, 84, 102, 0.7); */
  border-radius: 10px;
  opacity: 0;
  transition: .2s all;
}

.card:hover .image-info {
  /* background-color: rgb(215, 187, 68, 0.7); */
  /* rgb(214, 120, 39, 1); rgb(77, 84, 102, 0.7) */
  /* transition: .2s all; */
  /* opacity: 1; */
}

.card .image-info p {
  margin: 5px;
}

.footer {
  background-color: var(--principal);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 80px;
}

#banner_quienes_somos {
  /* background-attachment: fixed; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  color: white;
  background-image:
    linear-gradient(0deg, rgb(24, 29, 40, 0.2), rgb(24, 29, 40, 0.2));
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

#banner_quienes_somos div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  /* background: #191919;
  background: -webkit-gradient(linear,left bottom,left top,from(#191919),color-stop(50%,rgba(25,25,25,0)),to(#191919));
  background: linear-gradient(0deg,#191919,rgba(25,25,25,0) 50%,#191919); */
  padding: 0 20px;
  z-index: 1;
}

.first_section {
  margin-top: 218px !important;
}

.quienes_somos .section .section_inner .section_img img {
  width: 600px;
}

.quienes_somos .section2 .section_inner {
  width: 100%;
  align-items: start;
  justify-content: space-around;
}

.quienes_somos .section2 .section_inner .section_text {
  width: 25%;
}

.quienes_somos .section2 .section_inner .section_text div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 500px;
  background-color: var(--secundario_2);
  border-radius: 10px;
}

.quienes_somos .section2 .section_inner .section_text h2 {
  text-align: center;
}

.quienes_somos .section2 .section_inner .section_text p {
  text-align: center;
  color: black;
  border-radius: 10px;
  padding: 20px;
  font-weight: bold;
  font-size: 22px;
  /* height: 500px; */
}

.quienes_somos .section2 .section_inner .section_text:nth-child(1) div {
  background-color: var(--principal);
}

.quienes_somos .section2 .section_inner .section_text:nth-child(2) div {
  background-color: var(--secundario_3);
}

.quienes_somos .section2 .section_inner .section_text:nth-child(3) div {
  background-color: var(--secundario_2);
}

.quienes_somos .section2 .section_inner .section_text:nth-of-type(1) h2 {
  color: var(--principal);
}

.quienes_somos .section2 .section_inner .section_text:nth-of-type(2) h2 {
  color: var(--secundario_3);
}

.quienes_somos .section2 .section_inner .section_text:nth-of-type(3) h2 {
  color: var(--secundario_2);
}

.que_hacemos {
  width: 100%;
  height: 65vh;
}

.que_hacemos .section_que_hacemos {
  width: 100%;
  height: 80vh;
  background-color: white;
  margin: 40px auto;
  /* margin-top: 257px; */
  margin-bottom: 0px;
  max-width: 1696px;
  border-radius: 10px 10px 0 0;
  padding: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.que_hacemos .section_que_hacemos .que_hacemos_container {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  /* overflow: hidden; */
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .logo {
  position: absolute;
  max-width: 110px;
  width: 25vw;
  transform: scale(1) !important;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_de_accion {
  transform: scale(1) !important;
  width: 250px;
  padding-bottom: 50px;
}

.que_hacemos .section_que_hacemos .que_hacemos_container img {
  width: 50%;
  transition: 1s ease;

}

.que_hacemos .section_que_hacemos .que_hacemos_container img:hover {
  transform: scale(1.5);
  transition: 1s ease;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_accion:nth-of-type(1) img:nth-child(1):hover {
  transform-origin: right bottom;
  cursor: pointer;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_accion:nth-of-type(1) img:nth-child(2):hover {
  transform-origin: left bottom;
  cursor: pointer;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_accion:nth-of-type(2) img:nth-child(1):hover {
  transform-origin: right top;
  cursor: pointer;
}

.que_hacemos .section_que_hacemos .que_hacemos_container .lineas_accion:nth-of-type(2) img:nth-child(2):hover {
  transform-origin: left top;
  cursor: pointer;
}

.section2_que_hacemos {
  width: 100%;
  background-color: white;
  margin: 40px auto;
  max-width: 1696px;
  border-radius: 10px;
}

.section2_que_hacemos .img_container {
  width: 100%;
}

.section2_que_hacemos .img_container img {
  width: 100%;
}

.portafolio {
  width: 100%;
  background-color: white;
  margin: 40px auto;
  margin-top: 0px;
  padding-bottom: 40px;
  max-width: 1696px;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portafolio .portafolio_title {
  margin: 20px 40px;
  font-weight: bold;
  text-align: center;
}


.portafolio .img_container {
  width: 100%;
}

.portafolio .img_container img {
  width: 100%;
  margin: 10px 0;
}

.que_hacemos_container .col_1 {
  width: 25%;
  height: 100%;
}

.que_hacemos_container .col_1 .row_1 {
  height: 50%;
  opacity: 0;
  color: var(--secundario_3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.que_hacemos_container .col_1 .row_1 p {
  color: black;
}

.que_hacemos_container .col_1 .row_1 h2 {
  font-size: 2vw;
  font-weight: bold;
}

.que_hacemos_container .col_1 .row_2 p {
  color: black;
}

.que_hacemos_container .col_1 .row_2 h2 {
  font-size: 2vw;
  font-weight: bold;
}

.que_hacemos_container .col_1 .row_2 {
  height: 50%;
  opacity: 0;
  color: var(--principal);
  display: flex;
  justify-content: center;
  align-items: center;
}

.que_hacemos_container .col_2 {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: flasher 2s;
  height: 100%;
}

.que_hacemos_container .col_3 {
  width: 25%;
  height: 100%;
}

.que_hacemos_container .col_3 .row_3 {
  height: 50%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.que_hacemos_container .col_3 .row_3 p {
  color: black;
}

.que_hacemos_container .col_3 .row_3 h2 {
  font-size: 2vw;
  font-weight: bold;
}

.que_hacemos_container .col_3 .row_4 {
  height: 50%;
  opacity: 0;
  color: var(--secundario_2);
  display: flex;
  justify-content: center;
  align-items: center;
}

.que_hacemos_container .col_3 .row_4 p {
  color: black;
}

.que_hacemos_container .col_3 .row_4 h2 {
  font-size: 2vw;
  font-weight: bold;
}

.portafolio {
  width: 100%;
}

.portafolio .slider {
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: 1550px;
  max-height: 850px;
  padding-top: 0;
  /* margin-top: 217px; */
}

.portafolio .slider .slider_inner {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  border-radius: 0 0 10px 10px;
}

.portafolio .slider .slider_inner .items_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: transform 1s ease;
}

.portafolio .slider .slider_inner .items_container .item {
  flex: 0 0 100%;
  /* Evita que los elementos se redimensionen */
  max-width: 1550px;
  max-height: 850px;
}

.portafolio .slider .slider_inner .items_container .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Escalado para cubrir completamente el contenedor */
}

.portafolio .slider {
  padding-top: 0px;
}

.portafolio .slider-arrow-left {
  position: absolute;
  top: 40%;
  z-index: 10;
  left: 20px;
}

.portafolio .slider-arrow-right {
  position: absolute;
  top: 40%;
  z-index: 10;
  right: 20px;
}

@keyframes entrando_imagen_izq {
  from {
    transform: translate(10vw);
  }

  to {
    transform: translate(0px);
  }
}

@keyframes saliendo_imagen_izq {
  from {
    transform: translate(0vw);
  }

  to {
    transform: translate(10px);
  }
}

.experiencias {
  width: 100%;
}

.experiencias .slider {
  width: 100%;
  background-color: white;
  margin: 20px auto;
  margin-bottom: 40px;
  max-width: 1696px;
  border-radius: 10px;
  padding: 40px;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  overflow-x: hidden;
  /* justify-content: baseline; */
}

.descripcion {
  position: absolute;
  top: 0;
  left: 0;
}

/* .experiencias .slider {
  min-height: 300px;
  transition: width 10s, height 10s;
} */

.experiencias .slider .slider_inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  transition: ease 1s;
  position: relative;
  height: 100%;
  /* overflow-y: hidden; */
}

.experiencias .slider .slider_inner .item {
  width: 100%;
  /* height: 280px; */
  object-fit: cover;
  margin: 0 10px;
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* padding: 10px; */
  /* position: relative; */
  /* overflow: hidden; */
}

.experiencias .slider .slider_inner .item:hover {
  transform: scale(1.2);
  transition: ease 1s;
  animation: flasher 1s;
  z-index: 999;
}

.experiencias .slider .slider_inner .item:hover h2 {
  opacity: 1;
  font-size: 18px;
  color: black;
  background-color: var(--secundario_3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 10px;
}

.experiencias .slider .slider_inner .item img {
  max-width: 500px;
  height: 280px;
  border-radius: 10px;
  object-fit: cover;
}

.experiencias .slider .slider_inner .item h2 {
  /* position: absolute; */
  top: 50%;
  width: 100%;
  /* height: 100%; */
  text-align: justify;
  opacity: 0;
  display: none;
  transition: opacity 0.5s, top 0.5s;
  padding: 5px 15px;
}

.experiencias .container_slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.experiencias .container_slider .proyectos {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  margin-top: 50px;
  max-width: 1696px;
  align-items: stretch;
}

.experiencias .container_slider .proyectos h2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
  padding: 8px;
  background-color: var(--principal);
  color: black;
  font-weight: bold;
  border-radius: 10px;
  font-size: x-large;
  text-align: center;
  width: 100%;
  /* height: 250px; */
}

.experiencias .container_slider .proyectos h2:hover {
  background-color: var(--secundario);
  cursor: pointer;
}

.proyecto_activo {
  background-color: var(--secundario) !important;
}

.experiencias .slider-arrow {
  cursor: pointer;
  /* position: absolute; */
  /* top: 50%; */
  /* transform: translateY(-50%); */
  font-size: 24px;
  font-weight: bold;
  color: #333;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  transition: background-color 0.3s;
  opacity: .8;
}

.experiencias .slider-arrow:hover {
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 1;
}

.experiencias .slider-arrow-left {
  position: absolute;
  top: 150px;
  z-index: 10;
  left: 20px;
}

.experiencias .slider-arrow-right {
  position: absolute;
  top: 150px;
  z-index: 10;
  right: 20px;
}

.experiencias .section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.experiencias .section .section_title h2 {
  font-weight: bolder;
  margin: 25px 0;
}

.experiencias .section .comentarios {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

}

.experiencias .section .comentarios .comentario {
  width: 350px;
  font-weight: 600;
  margin: 10px 20px;
  padding: 25px 20px;
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: var(--principal);
  /* padding: 100px; */
}

.experiencias .section .comentarios .comentario p:nth-child(2) {
  text-align: right;
  margin: 0;
}

.experiencias .section .caja_comentario h2 {
  font-weight: bolder;
  margin: 25px 0;
}

.experiencias .section .caja_comentario textarea {
  width: 100%;
  height: 100px;
}

.experiencias .section .caja_comentario button {
  /* width: 100%; */
  margin: 10px 0;
  float: right;
  padding: 5px 100px;
  background-color: var(--secundario_2);
  color: white;
  font-weight: bolder;
  border-radius: 10px;
}

.contactenos .mapa {
  width: 100%;
}

.contactenos .mapa iframe {
  border-radius: 10px;
}

.contactenos .section .section_inner {
  width: 100%;
  height: 450px;
  justify-content: space-around;
}

.contactenos .section .section_inner .datos_contacto {
  width: 100%;
  height: 100%;
  background-color: var(--principal);
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.contactenos .section .section_inner .datos_contacto p {
  display: flex;
  align-items: center;
}

.contactenos .section h1 {
  font-weight: bold;
  text-align: center;
  margin-bottom: 40px;
}

.inner_contactanos_1 {
  width: 100%;
  /* margin-left: 25px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.inner_contactanos_formulario {
  width: 50%;
}

#formulario-contactanos {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.container-contactanos {
  width: 100%;
  border-radius: 10px;

}

.formulario_items {
  width: 100%;
  /* height: 200px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

#formulario-contactanos input {
  width: 49%;
  height: 70px;
  margin: 10px 5px;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
  /* opacity: 0.5; */
}

#formulario-contactanos select {
  width: 49%;
  height: 70px;
  margin: 10px 5px;
  border-radius: 10px;
  border: none;
  padding: 10px 15px;
  /* opacity: 0.5; */
}

#formulario-contactanos textarea {
  border-radius: 10px;
  border: none;
  padding: 20px 15px;
  /* opacity: 1;  */
  width: 100%;
  margin: 10px 10px;
  resize: none;
}

#formulario-contactanos button {
  width: 40%;
  height: 70px;
  margin: 10px 0;
  background-color: var(--secundario);
  font-weight: bold;
  font-size: 1.25em;
  color: white;
  border-radius: 10px;
  border: none;
}

.donaciones-buttons {
  position: fixed;
  bottom: 50px;
  z-index: 1000;
  background-color: var(--principal);
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 10px 10px 10px 10px;
  left: -200px;
  transition: .2s all;
}

#root .donaciones-buttons i {
  margin: 0 10px;
}

.section_donacion {
  /* width: 100%; */
  /* margin-left: 25px; */
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  margin-top: 20px;
}

.no_ident::-webkit-outer-spin-button,
.no_ident::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.panel_admin .wraper .container {
  display: none;
}

.panel_admin .section_panel_admin {
  display: flex;
  flex-direction: row;
  margin-top: 66px;
}

.panel_admin .section_panel_admin .pages {
  width: 20%;
  display: flex;
  flex-direction: row;
}

.panel_admin .section_panel_admin .pages ul {
  list-style-type: none;
  font-weight: bold;
  margin: 10px;
  background-color: var(--principal);
  width: 100%;
  border-radius: 10px;
}

.panel_admin .section_panel_admin .pages li {
  margin: 20px 0;
  cursor: pointer;
}

.page_active {
  color: var(--secundario_2);
}

.panel_admin .section_panel_admin .pages_content {
  margin: 10px;
  width: 80%;
  min-height: 800px;
  border-radius: 10px;
}

.panel_admin .section_panel_admin .pages_content .panel_inicio .panel_rows .panel_items {
  background-color: #E8EDF5;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
}

.panel_admin .section_panel_admin .pages_content .panel_inicio .panel_rows .panel_items h2 {
  margin-left: 10px;
}

.img_preview {
  width: 300px;
  padding: 0px 15px;
  margin: 0px 5px;
}

.regimen .inner_regimen {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.regimen .inner_regimen .sidebar {
  width: 20%;
  height: 90vh;
  background-color: var(--principal);
  margin: 10px;
  border-radius: 10px;
}

.regimen .inner_regimen .sidebar ul {
  margin: 20px 5px;
  list-style-type: none;
}

.regimen .inner_regimen .sidebar ul li {
  margin: 20px 5px;
  font-weight: bold;
  cursor: pointer;
}

.regimen_active {
  color: var(--secundario_2);
}

.regimen .inner_regimen .documentos {
  margin: 10px auto;
  width: 75%;
}

.regimen .inner_regimen .documentos .inner_documentos {
  width: 100%;
}

.regimen .inner_regimen .documentos .inner_documentos iframe {
  width: 100%;
  height: 90vh;
}

.regimen .wraper .container {
  display: none;
}

.desktop {
  display: flex;
}

.mobile {
  display: none;
}

.signin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.signin .alert {
  background-color: #fbbb44;
  width: 400px;
  margin: 10px 0;
  color: var(--secundario_2);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.login {
  max-width: 500px;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid var(--secundario); */
  border-radius: 8px;
  padding: 48px 40px 36px;

  background-color: var(--principal);
  color: var(--secundario);
}

.login .title {
  text-align: center;
}

.login .content {
  display: flex;
  flex-direction: column;
}

.login .content input,
button {
  color: var(--secundario);
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  padding: 6px 8px;
}

.login button {
  width: 100%;
  background-color: var(--secundario);
  color: white;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  padding: 6px 8px;
}

.lazy-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container-spinner {
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-spinner img {
  margin: 10px 0;
}

.container-spinner .progress_bar {
  width: 100%;
  margin: 10px 0;
}

.container-spinner p {
  margin: 10px 0;
}

.progress {
  background-color: var(--secundario);
}

#regresar_inicio {
  width: 300px;
  background-color: var(--secundario);
  color: white;
  margin: 10px 0;
  border-radius: 4px;
  border: none;
  padding: 6px 8px;
  text-decoration: none;
}

@media(max-width: 1540px) {
  .quienes_somos .section2 .section_inner {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }

  .quienes_somos .section2 .section_inner .section_text {
    width: 400px;
  }

  .quienes_somos .section2 .section_inner .section_text div p {
    font-weight: bold;
  }

}

@media(max-width: 1400px) {
  .contactenos .section .section_inner {
    flex-direction: column;
    height: 100%;
  }

  .contactenos .section .section_inner .mapa iframe {
    width: 100%;
  }

  .que_hacemos_container .col_2 {
    width: 40%;
  }

  /* .que_hacemos_container .col_1 .row_1 h2{
    font-size: 3vw;
  }

  .que_hacemos_container .col_1 .row_2 h2{
    font-size: 3vw;
  }

  .que_hacemos_container .col_3 .row_3 h2{
    font-size: 3mvw;
  }

  .que_hacemos_container .col_3 .row_4 h2{
    font-size: 3vw;
  } */
}

@media(max-width: 1150px) {
  @keyframes abriendo_menu {
    from {
      transform: translateY(-30vh);
    }

    to {
      transform: translateY(0px);
    }
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: flex;
  }

  .navbar {
    justify-content: right;
    padding: 0;
  }

  .navbar-button {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 10px;
    border: none;
    border-radius: 0;
  }

  .navbar-button .menu_button {
    /* border: 1px solid; */
    /* border-radius: 5px; */
    margin: 5px;
    width: 30px;
    height: 30px;
  }

  .inicio {
    position: relative;
  }

  .navbar .container-fluid {
    flex-direction: column;
    display: none;
  }

  .inicio .section .section_inner {
    flex-direction: column;
  }

  .inicio .section .section_inner .section_text h2 {
    text-align: center;
  }

  /* .inicio .section .section_inner .section_img{
    width: 100%;
    text-align: center;
  }
  .inicio .section .section_inner .section_img img{
    width: 50%;
  } */
  .quienes_somos .section .card-container {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }

  .quienes_somos .section .card-container .card {
    max-width: none;
  }

  .quienes_somos .section .section_inner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .quienes_somos .section .section_inner .section_text h2 {
    text-align: center;
  }

  .que_hacemos_container .col_2 {
    width: 90%;
  }

  .regimen .inner_regimen .sidebar {
    width: 30%;
  }

  .regimen .inner_regimen .documentos {
    width: 70%;
  }
}

@media(max-width: 900px) {
  .que_hacemos .section_que_hacemos {
    height: 55vh;
  }

  .que_hacemos_container .col_1,
  .col_3 {
    display: none;
  }

  .que_hacemos_container .col_2 {
    width: 100%;
  }

  .experiencias .container_slider .proyectos {
    flex-wrap: wrap;
  }

  .experiencias .container_slider .proyectos h2 {
    margin: 10px 20px;
  }

  .experiencias .section .comentarios .comentario {
    width: 100%;
    min-width: 300px;
  }

  .regimen .inner_regimen .sidebar {
    width: 40%;
  }

  .regimen .inner_regimen .documentos {
    width: 60%;
  }

  .footer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10px;
  }

  .footer p,
  a {
    margin: 10px 0 !important;
  }
}

@media(max-width: 700px) {
  .redes-header {
    display: none;
  }

  .regimen .inner_regimen {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .regimen .inner_regimen .sidebar {
    width: 90%;
    height: 100%;
  }

  .regimen .inner_regimen .documentos {
    width: 90%;
  }

  .contactenos .section .inner_contactanos_1 #formulario-contactanos .formulario_items {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contactenos .section .inner_contactanos_1 #formulario-contactanos .formulario_items input {
    width: 100%;
  }

  .quienes_somos .section .section_inner .section_img img {
    width: 100%;
  }

  .experiencias .slider .slider_inner .item img {
    height: 250px;
  }
}

@media(max-width: 500px) {
  .experiencias .slider .slider_inner .item img {
    height: 220px;
  }

  .quienes_somos .section2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .quienes_somos .section2 .section_inner .section_text {
    width: 300px;
    height: 600px;
  }

  .quienes_somos .section2 .section_inner .section_text div {
    height: 100%;
  }

  .quienes_somos .section2 .section_inner .section_text div p {
    font-size: 20px;
  }
}